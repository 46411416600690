export const environment = {
    production: true,
    VERSION: require('../../package.json').version,
    AUTHPASS: btoa('test:test'),
    FILE_UPLOAD_MAX_SIZE: 5097152,
    GUI_TYPE: '0',
    INTERCOM_KEY: 'ky5rkdlu',
    TAWK_KEY: '5f101d955b59f94722bad5fe',
    matomoEnabled: true,
    auth: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/oauth/token',
    },
    captcha: {
        url: '/captcha',
    },
    erp: {
        v1: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            apiPort: '',
            apiUrl: '/erp/api',
        },
        v2: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            apiPort: '',
            apiUrl: '/erp/api',
        },
    },
    assistance: {
        v1: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/assistance/api',
        },
        v2: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/v2/assistance/api',
        },
        v3: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/asv2/assistance/api',
        },
    },
    odoo: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/asv2/odo/api',
    },
    document: {
        v1: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/erp/docapi',
        },
        v2: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/erp/docapi',
        },
    },
    authorization: {
        v1: {
            protocol: 'http://',
            rootUrl: 'app.eambulanta.si/booking',
            port: ':9090',
            url: '/authorization/api',
        },
        v2: {
            protocol: 'http://',
            rootUrl: 'app.eambulanta.si/booking',
            port: ':9090',
            url: '/authorization/api',
        },
    },
    access: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/access',
    },
    onesignal: {
        appId: '54adba67-27df-41c5-8b4b-5fed71d38b72',
        allowLocalhostAsSecureOrigin: false,
        defaultUrl: 'https://asistent.medifit.si',
        defaultTitle: 'asistent | Medifit',
        safariWebId: 'web.onesignal.auto.05255d72-c150-4ea5-804e-422907370478',
    },

    root: {
        v3: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/asv2',
        },
    },
    aircall: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/eav2/erp/aircallapi',
    },
};